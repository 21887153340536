import React from 'react';
import { Badge, Fab } from '@material-ui/core';
import { Link } from 'gatsby';
import { FormType } from '../services/lists';
import { useInteractionsContext } from './interactionsContext';

export interface InteractionBadgeProps {
  type: FormType;
}

export const InteractionBadge: React.FC<InteractionBadgeProps> = ({
  type,
  children,
}) => {
  const { interactionCounts } = useInteractionsContext();
  return (
    <Link
      to={`/active-interactions#${type}`}
      aria-label={`go to active ${type} interactions`}
    >
      <Fab size="small">
        <Badge badgeContent={interactionCounts[type]} color="secondary">
          {children}
        </Badge>
      </Fab>
    </Link>
  );
};

import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  makeStyles,
  Theme,
  IconButton,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

export interface TooltipProps {
  open: boolean;
  close: VoidFunction;
}

const AddClientsTooltip: React.FC<TooltipProps> = ({ open, close }) => {
  const classNames = useStyles();

  return (
    <>
      <Dialog open={open} onClose={close}>
        <DialogTitle>
          <Typography variant="h6" component="h2">
            Individual Client Details
          </Typography>
          <IconButton
            aria-label="close"
            className={classNames.closeButton}
            onClick={close}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" component="h3">
            Adjusting Details
          </Typography>
          <Typography variant="body2">
            When clicking Yes, this indicates that you wish to enter different
            information for 1 or more clients on this trip.
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddClientsTooltip;
